.Home-repo-grid {
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Home-RepoListing-wrapper {
  width: 100%;
  margin-bottom: 20px;
}
