.UserListing-location,
.UserListing-email {
  width: 15px;
  height: auto;
  margin-right: 5px;
}

.UserListing-email {
  margin-left: 20px;
}
