.Search-types {
  border: 1px solid gray;
  padding: 10px;
}

.Search-types a {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Search-active {
  font-weight: bold;
}