.Tree {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Tree-tree,
.Tree-blob {
  padding: 10px 0 10px 35px;
  background-image: url("./tree.svg");
  background-repeat: no-repeat;
  background-position: 0 8px;
  background-size: 25px auto;
  border-bottom: 1px solid rgb(223, 223, 223);
}

.Tree-blob {
  background-image: url("./blob.svg");
}
