.RepoListing-star {
  width: 15px;
  height: auto;
  margin-right: 5px;
}

.RepoListing-badge {
  padding: 8px !important;
}

.RepoListing-title {
  word-break: break-all;
}
